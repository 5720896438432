/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    ButtonBase,
    Dialog,
    makeStyles,
    Typography,
    SwipeableDrawerProps,
    Box,
} from '@material-ui/core';
import clsx from 'clsx';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import COLORS from 'src/lib/colors';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import ROUTES from 'src/lib/routes';
import { addShipmentTracking } from 'src/lib/api-client/shipment-services';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import { Body, Button, Heading, SwipeableDrawer } from '../../UI';
import { IconScanShipping, IconCornerSplashRight } from '../../Icons';
import styles from './styles.module.scss';
import Brcd from '../../BarcodeScanner/Brcd';
import LoadingOverlay from '../../loading-overlay';
import ScanModal from '../components/ScanModal';
import useStyles from '../styles/shipmentServicesStyles';

interface ShippingRewardsInfoDialogProps extends SwipeableDrawerProps {
    points: string | undefined;
    hideBtn?: boolean;
}

function ShippingRewardsInfoDialog(props: ShippingRewardsInfoDialogProps) {
    const classes = useStyles();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);

    const { isRewardShipperEnable } = useUserInfo();
    const {
        handleClose,
        getShipmentTrackingDetails,
        scanMessage,
        scannerData,
        isLoading,
        setScannerData,
        isScanCompleted,
        trackingError,
    } = useShipmentTracking();

    const { push } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const { updateFetchSenderInfo, updateReviewStage, updateShippingTab } =
        useContext(ShippingInfoContext);

    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'go to lables and tracking',
            element_location: 'scan successful',
        });
    };

    const handleStartShipment = () => {
        updateFetchSenderInfo(true);
        updateReviewStage(false);
        push(
            {
                pathname: ROUTES.shippingServices,
                state: true,
                // search: '?step=createShipmentSenderInfo',
            },
            { path: ROUTES.rewardsWallet }
        );
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'start a shipment',
            element_location: 'frequent shipper activated drawer',
        });
    };

    const handleScan = (elLoc: string) => {
        if (
            isRewardShipperEnable &&
            localStorage.getItem('startShipment') !== 'true'
        ) {
            push({
                pathname: ROUTES.shippingServices,
                state: true,
                search: '?step=scanToTrack',
            });
            analyticsCustomEvent(eventMetricsMap.shipping, {
                click_text: 'scan to track',
                element_location: 'stores page',
            });
        } else {
            setIsBarcodeScannerOpen(true);
            if (elLoc === 'scan_successful_modal') {
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan another package',
                    element_location: 'scan successful',
                });
            } else {
                analyticsCustomEvent(eventMetricsMap.shipping, {
                    click_text: 'scan to track',
                    element_location: 'frequent shipper activated drawer',
                });
            }
        }
    };

    // const getShipmentTrackingDetails = async (trackingnumber: any) => {
    //     setIsLoading(true);
    //     const response = await addShipmentTracking(trackingnumber);
    //     setScannerData('');
    //     setIsLoading(false);
    //     if (response?.issuccess) {
    //         setTrackingError(true);
    //         if (response?.status === 'SUCCESS') {
    //             setTrackingError(false);
    //             setIsScanCompleted(true);
    //             setScanMessage({
    //                 title: 'Scan successful!',
    //                 description:
    //                     'Visit Labels and tracking to view its current ' +
    //                     'status or scan another package.',
    //             });
    //             analyticsCustomEvent(eventMetricsMap.shipping, {
    //                 click_text: 'scan success',
    //                 element_location: 'scanner',
    //             });
    //         } else if (response?.status === 'Fail') {
    //             setScanMessage({
    //                 title: 'Your package couldn’t be found',
    //                 description:
    //                     'We couldn’t find your package at this time. Please try again later.',
    //             });
    //         } else if (response?.status === 'DUPLICATE_TRACKING') {
    //             setScanMessage({
    //                 title: 'Duplicate label',
    //                 description:
    //                     'This label has been previously scanned ' +
    //                     'and is already in the system.',
    //             });
    //         } else if (response?.status === 'INVALID_TRACKING') {
    //             setScanMessage({
    //                 title: `Your package couldn't be found`,
    //                 description:
    //                     'Make sure you have a UPS label and ' +
    //                     'shipment number to start tracking.',
    //             });
    //         } else if (response?.status === 'NONUS_TRACKING') {
    //             setScanMessage({
    //                 title: 'Want to ship internationally?',
    //                 description:
    //                     'Bring your package to your local Staples store ' +
    //                     'and we’ll ship it anywhere in the world.',
    //             });
    //         } else {
    //             setScanMessage({
    //                 title: 'Label couldn’t be scanned',
    //                 description:
    //                     'We couldn’t scan your label at this time. Please try again later.',
    //             });
    //         }
    //         setIsScanCompleted(true);
    //         setScannerData('');
    //     } else {
    //         setTrackingError(true);
    //         setIsScanCompleted(true);
    //         setScanMessage({
    //             title: 'Your package couldn’t be found',
    //             description:
    //                 'We couldn’t find your package at this time. Please try again later.',
    //         });
    //     }
    // };

    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData);
        }
    }, [scannerData]);

    return (
        <>
            {isLoading && (
                <LoadingOverlay
                    variant="wordmark"
                    theme="transparent-light"
                    open={isLoading}
                />
            )}
            <SwipeableDrawer
                anchor="bottom"
                classes={{
                    root: classes['MuiSwipeableDrawer-root'],
                }}
                open={props.open}
                onClose={props.onClose}
                onOpen={props.onOpen}
                drawerAdornment={
                    <span className={styles.drawerAdornment}>
                        <IconCornerSplashRight height={82} width={114} />
                    </span>
                }
            >
                <div className={styles.content}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Heading
                            type="h1"
                            color="textPurple"
                            size={32}
                            weight="bold"
                            textAlign="textCenter"
                        >
                            {props.points} POINTS
                        </Heading>
                        <Box
                            sx={{
                                width: '80%',
                            }}
                        >
                            <Body
                                size={16}
                                weight="light"
                                textAlign="textCenter"
                            >
                                when you ship or drop off a package in store.
                            </Body>
                        </Box>
                    </Box>
                    {!props.hideBtn ? (
                        <>
                            <Button
                                bgColor="bgBlack"
                                color="textWhite"
                                variant="contained-rounded"
                                className={styles.startShipments}
                                onClick={handleStartShipment}
                            >
                                Start a shipment
                            </Button>
                            <Button
                                variant="icon"
                                color="textLinkBlue"
                                onClick={() => handleScan('drawer')}
                            >
                                <span className={styles.buttonContent}>
                                    <IconScanShipping /> Scan to track
                                </span>
                            </Button>
                        </>
                    ) : (
                        ''
                    )}

                    <Body size={10} weight="light">
                        Valid on UPS® and USPS® package drop-offs and
                        full-service shipments in Staples® U.S. stores only. All
                        other methods of shipping will not qualify. Not valid on
                        Instacart, Door Dash or Uber Eats orders. Offer
                        available to Staples Easy Rewards™ members only. For
                        full-service shipments, member must provide membership
                        number to associate at checkout. May be used for up to
                        10 shipments per Staples Easy Rewards member per week,
                        nontransferable. Points only awarded on shipments with
                        labels created in store or scanned through the Staples
                        app for drop-off, processed by an associate and shipped
                        by carrier. Earned points may take up to 21 days from
                        the carrier receiving shipment(s) to appear in member’s
                        account. Offer may not be combined with any other
                        Staples Easy Rewards promotion in a single transaction.
                        Not valid on prior purchases or purchases made with
                        Staples Advantage In-Store Purchase Program. Offer is
                        subject to change or cancellation at any time. Staples
                        Easy Rewards program terms and conditions apply. For
                        full program details visit staples.com/easy. Expires
                        4/30/25.
                    </Body>
                </div>
            </SwipeableDrawer>
            {isBarcodeScannerOpen && (
                <Brcd
                    isScannerOpen={isBarcodeScannerOpen}
                    shippingScan
                    onClose={() => {
                        setIsBarcodeScannerOpen(false);
                        handleClose();
                    }}
                />
            )}
            {isScanCompleted && (
                <ScanModal
                    handleSavedLabelShipment={handleSavedLabelShipment}
                    handleScan={handleScan}
                />
            )}
        </>
    );
}

export default ShippingRewardsInfoDialog;
