/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import IconStore from 'src/components/Icons/IconStore';
import {
    IconEasyRewardsHorizontal,
    IconInfo,
    IconClose,
    IconCalculator,
    IconStaplesRound,
    IconRecentPurchase,
    IconWeeklyAd,
    IconInfoWhite,
    IconScanner,
    IconDollarCoin,
    IconPeople,
    IconProfile,
    IconSmallCheckmark,
} from 'src/components/Icons';
import { Body, Button, Heading } from 'src/components/UI';
import useStoreData from 'src/hooks/useStoreData';
import useCardCanvas from 'src/hooks/useCardCanvas';
import { storeCommunity } from 'src/lib/utils/store-utils';
import SearchForProduct from 'src/components/search-for-product-home';
import FullWidthCard from 'src/components/cards/full-width-card';
import {
    eventMetricsMap,
    useEventsReporter,
    useGuestMode,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import Brcd from 'src/components/BarcodeScanner/Brcd';
import layout from 'src/stylesheets/layout_classes.module.scss';
import styleUtil from 'src/stylesheets/utilities.module.scss';
import colors from 'src/stylesheets/color_classes.module.scss';
import { mergeClasses } from 'src/stylesheets/utils';
import {
    rewardsPostLoyaltyAccountURL,
    rewardsGetCouponDataURL,
} from 'src/services/urls';
import { getLoyaltyDetails } from 'src/lib/api-client/loyalty-service';
import { numberWithCommas } from 'src/lib/utils/app-utils';
import {
    activateOffer,
    getCouponDetails,
    getFeaturedLoyaltyOffers,
    getLoyaltyOffers,
} from 'src/services/easyRewardsServices';
import AppLink from 'src/components/link';
import ROUTES from 'src/lib/routes';
import { useAtom } from 'jotai';
import COLORS from 'src/lib/colors';
import BrandIframe from 'src/components/BrandIframe';
import Header from 'src/components/header';
import NavBottom from 'src/components/NavBottom';
import { useUserUpdateContext } from 'src/lib/contexts/user-update-context';
import { useTabsUIStateContext } from 'src/lib/contexts/tabs-ui-state-context';
import { useCartCountContext } from 'src/lib/contexts/cart-count-context';
import { useProductBrowseContext } from 'src/lib/contexts/product-browse-context';
import { toastAtom } from '../EasyRewards/OfferList/OfferCard';
import styles from './style.module.scss';
import AlertMessage from '../alert-message';
import RecyclingCartridgeEarning from '../EasyRewards/RecyclingCartridgeEarning';
import PointCalculator from './point-calculator';

interface QuickLinkCardProps {
    text: string;
    onClickHandler: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
    icon: React.ReactNode;
}

const QuickLinkCard: React.FC<QuickLinkCardProps> = ({
    text,
    onClickHandler,
    icon,
}) => (
    <FullWidthCard
        className={styles.guestModeQuickLinkCard}
        noPadding
        onClick={onClickHandler}
    >
        <div
            className={mergeClasses(
                layout.flex,
                layout.flexCol,
                layout.alignCenter
            )}
            style={{ width: '6.8rem' }}
        >
            {icon}
            <Body size={16} color={COLORS.darkGray} lineHeight={35}>
                {text}
            </Body>
        </div>
    </FullWidthCard>
);

function StoreModeDashboard() {
    const {
        nearestStore,
        dismissStoreModeScreen,
        mainStore,
        exitStoreMode,
        isStoreModeOn,
    } = useStoreData();
    const { userInfo } = useUserInfo();
    const { canvasRef } = useCardCanvas(userInfo?.rewardsNumber);
    const [loyaltyData, setLoyaltyData] = useState<any>({});
    const [featuredOffer, setFeaturedOffer] = useState<any>({});
    const [couponsInfo, setCouponsInfo] = useState<any>({});
    const [offersToActivate, setOffersToActivate] = useState<any>(0);
    const { history, push } = useRouter();
    const { loadIframeUrl, setLoadIframeUrl } = useTabsUIStateContext();
    const { analyticsCustomEvent } = useEventsReporter();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);
    const [hideBarcodeScannerIcon, setHideBarcodeScannerIcon] = useState(false);
    const [isCalculatorOn, setIsCalculatorOn] = useState(false);
    const { setGuestModal } = useUserUpdateContext();
    const { end } = useProductBrowseContext();
    const { isGuestModeOn } = useGuestMode();

    const handleStoreModeClose = () => {
        dismissStoreModeScreen();
        history.push(ROUTES.home);
    };

    const handleStoreModeExit = () => {
        exitStoreMode();
        history.push(ROUTES.home);
    };

    const getLoyaltyInfo = async () => {
        if (userInfo?.rewardsNumber) {
            const loyaltyPayload = {
                accountIdentifier: userInfo?.rewardsNumber,
                identifierType: 'REWARDS',
            };
            const loyaltyResponse = await getLoyaltyDetails(
                rewardsPostLoyaltyAccountURL,
                loyaltyPayload
            );
            if (loyaltyResponse) {
                setLoyaltyData(
                    loyaltyResponse?.accounts[0].walletinfo?.balances
                );
            }
        }
    };

    const getCouponInfo = async () => {
        if (userInfo?.rewardsNumber) {
            const url = rewardsGetCouponDataURL.replace(
                '%rewardsnumber%',
                userInfo.rewardsNumber || '0'
            );
            const couponsResponse = await getCouponDetails(url);
            if (couponsResponse?.couponDataList?.length > 0) {
                const activeCoupons = couponsResponse.couponDataList?.filter(
                    (coupon: any) =>
                        coupon.couponInfo.isRedeemed === false &&
                        coupon.couponInfo.validForRetail === true
                );

                if (activeCoupons.length > 0) {
                    setCouponsInfo(activeCoupons);
                }
            }
        }
    };

    const { data: featuredOffers, refetch: refetchFeaturedOffers } =
        getFeaturedLoyaltyOffers({
            config: {
                onSuccess: (res) => {
                    if (res && res?.offers?.length > 0) {
                        const filteredOffers = res.offers.filter(
                            (offer) =>
                                offer.channel === 'Retail' ||
                                offer.channel === 'Retail and Dotcom'
                        );
                        if (filteredOffers.length > 0) {
                            setFeaturedOffer(filteredOffers[0]);
                        }
                    }
                },
            },
        });

    const { data: massOffers, refetch: refetchMassLoyaltyOffers } =
        getLoyaltyOffers({
            offerType: 'MASS',
        });

    const { data: targetedOffers, refetch: refetchTargetedLoyaltyOffers } =
        getLoyaltyOffers({
            offerType: 'TARGETED',
        });

    useEffect(() => {
        const offersToActivateCount = [
            ...(featuredOffers?.offers || []),
            ...(massOffers?.offers || []),
            ...(targetedOffers?.offers || []),
        ].filter((offer) => offer.activated === false).length;
        setOffersToActivate(offersToActivateCount);
    }, [featuredOffers, massOffers, targetedOffers]);

    useEffect(() => {
        end();
        analyticsCustomEvent(eventMetricsMap.user_info, {
            user_property: 'store_mode',
            key: true,
        });

        return () => {
            analyticsCustomEvent(eventMetricsMap.user_info, {
                user_property: 'store_mode',
                key: false,
            });
        };
    }, []);

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        isGuestModeOn && setHideBarcodeScannerIcon(true);
        getLoyaltyInfo();
        getCouponInfo();
        
        analyticsCustomEvent(eventMetricsMap.screen_view, {
            firebase_screen: userInfo?.rewardsNumber? 'storemode_logged in':'storemode_guest',
            firebase_screen_class: userInfo?.rewardsNumber? 'storemode_logged in':'storemode_guest',
        }); 
   
    }, [userInfo?.rewardsNumber]);

    const navigateToTarget = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        url: any
    ): void => {
        event.preventDefault();
        if (url.includes(`${process.env.REACT_APP_DOT_COM_URL}`)) {
            setLoadIframeUrl(url);
        } else {
            history.push(url);
        }
    };
    const seeAllCoupons = (event: any): void => {
        event.preventDefault();
        analyticsCustomEvent('easy_rewards', {
            click_text: 'see all coupons',
            element_location: 'storemode',
        });
        event.stopImmediatePropagation();
    };
    const activateAllOffers = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ): void => {
        event.preventDefault();
        event.stopPropagation();

        analyticsCustomEvent('easy_rewards', {
            click_text: 'activate all',
            element_location: 'storemode',
        });
        history.push(
            `${ROUTES.rewardsWallet}?tabIndex=1${
                offersToActivate > 0 ? '&activateAllOffers=true' : ''
            }`
        );
    };

    const getActivateOfferText = () =>
        offersToActivate > 0 ? (
            `Activate all ${offersToActivate} offers`
        ) : (
            <span className={styles.activeButton}>
                <IconSmallCheckmark width={14} height={14} /> All offers are
                activated
            </span>
        );

    const handleClickOnBack = () => {
        setLoadIframeUrl('');
    };

    const handleCalculator = () => {
        setIsCalculatorOn(true);
    };

    const handleFeaturedOfferClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_logged in', {
            click_text: `${featuredOffer.name} ${featuredOffer.offerdescription}`,
            element_location: 'module 1',
        });
        navigateToTarget(event, `${ROUTES.rewardsWallet}?tabIndex=1`);
    };

    const handleCouponOfferClick = (event: any) => {
        event.preventDefault();
        if (event.target.innerText.includes('store coupons')) {
            analyticsCustomEvent('easy_rewards', {
                click_text: 'see all coupons',
                element_location: 'storemode',
            });
        } else {
            analyticsCustomEvent('storemode_logged in', {
                click_text: `${couponsInfo[0]?.couponInfo?.descriptionText1} ${couponsInfo[0]?.couponInfo?.descriptionText2}`,
                element_location: 'module 2',
            });
        }
        navigateToTarget(event, `${ROUTES.rewardsWallet}?tabIndex=2`);
    };

    const handleRecentPurchaseClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_logged in', {
            click_text: `recent purchases`,
            element_location: 'module 3',
        });
        navigateToTarget(
            event,
            `${process.env.REACT_APP_DOT_COM_URL}/ptd/myorders`
        );
    };

    const handleWeeklyAddClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_logged in', {
            click_text: `weekly ad`,
            element_location: 'module 4',
        });
        navigateToTarget(
            event,
            `${process.env.REACT_APP_DOT_COM_URL}/lp/weekly-ad`
        );
    };

    const handleScanProductClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_guest', {
            click_text: 'scan a product',
            element_location: 'module 1',
        });
        setIsBarcodeScannerOpen(!isBarcodeScannerOpen);
    };

    const handleWeeklyAdClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_guest', {
            click_text: 'weekly ad',
            element_location: 'module 2',
        });
        navigateToTarget(
            event,
            `${process.env.REACT_APP_DOT_COM_URL}/lp/weekly-ad`
        );
    };

    const handleHotDealsClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_guest', {
            click_text: 'hot deals',
            element_location: 'module 3',
        });
        navigateToTarget(
            event,
            `${process.env.REACT_APP_DOT_COM_URL}/deals/Staples-Daily-Deals/SEGDD0000001 `
        );
    };

    const handleSignInClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_guest', {
            click_text: 'sign in',
            element_location: 'module 5',
        });
        setGuestModal({ open: true });
    };

    const handleStoreServicesClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        analyticsCustomEvent('storemode_guest', {
            click_text: 'store services',
            element_location: 'module 4',
        });
        push(ROUTES.stores);
    };

    useEffect(() => {
        if (!isStoreModeOn) {
            history.push(ROUTES.home);
        }
    }, [isStoreModeOn]);

    if (loadIframeUrl) {
        return (
            <>
                <Header
                    position="fixed"
                    headerFull={false}
                    backButton
                    onClickHandler={handleClickOnBack}
                />
                <Box style={{ paddingTop: '0' }}>
                    <BrandIframe
                        src={loadIframeUrl}
                        title="Shopping-online-screen"
                        id="shopping-online-screen-iframe"
                        height={'calc(100vh - 70px)'}
                    />
                </Box>
                <NavBottom />
            </>
        );
    }

    return isCalculatorOn ? (
        <PointCalculator
            handleCalculatorBack={() => setIsCalculatorOn(false)}
        />
    ) : (
        <div className={styles.storeModeContainer}>
            <button
                type="button"
                id="store-mode-dismiss"
                className={styles.closeButton}
                aria-label="close store mode"
                onClick={handleStoreModeClose}
            >
                <IconClose />
            </button>
            <div className={styles.headerContainer}>
                    <div className={styles.header}>
                        <IconStore className={styles.logo}/>
                        <Heading
                            type="h2"
                            className={styles.heading}
                        >
                            Store mode
                        </Heading>
                        <IconInfoWhite />
                    </div>
                    <Body className={styles.yourStore} >
                        Welcome to Staples{' '}
                        {storeCommunity(nearestStore?.[0] || mainStore) ||
                            'Unknown'}
                    </Body>
                    <SearchForProduct 
                        id="store-mode-search"
                        placeholder="Look up a product or service"
                        inStoreMode
                        decoy
                        mainStore={nearestStore?.[0] || mainStore}
                        hideBarcodeScannerIcon={hideBarcodeScannerIcon}
                    />
            </div>
            {userInfo ? (
                <>
                    <FullWidthCard className={styles.card} noPadding>
                        <div
                            className={mergeClasses(
                                layout.flex,
                                layout.alignCenter,
                                layout.justifySpaceBetween
                            )}
                        >
                            <IconEasyRewardsHorizontal width={100} />
                            <Body weight="bold" className={colors.textRed} ariaLabel={`Your Easy Rewards number is ${userInfo?.rewardsNumber?.split('').join(' ')}`}>
                                {userInfo?.rewardsNumber}
                            </Body>
                        </div>

                        <canvas
                            tabIndex={0}
                            aria-label="Your personal Easy Rewards barcode is here to scan at checkout when you are in a Staples store"
                            ref={canvasRef}
                            className={styles.renderedCardNumber}
                        />

                        <div
                            className={mergeClasses(
                                layout.flex,
                                layout.alignCenter,
                                layout.justifySpaceBetween,
                                layout.mt2
                            )}
                        >
                            <div
                                className={mergeClasses(
                                    layout.flex,
                                    layout.alignCenter,
                                    layout.justifySpaceBetween,
                                    layout.fullWidth
                                )}
                                aria-label={`Your available points is ${loyaltyData?.usable} points - Thats like ${loyaltyData?.usablevalue} dollars in savings`}
                            >
                                <Body>Available points</Body>
                                <Body size={16} weight="bold">
                                    <span className={styles.coin} />
                                    {loyaltyData?.usable &&
                                        numberWithCommas?.(loyaltyData.usable)}
                                </Body>
                            </div>

                            <button
                                onClick={handleCalculator}
                                type="button"
                                className={mergeClasses(
                                    styleUtil.ariaButton,
                                    styles.calculator
                                )}
                                id="store-mode-calculator"
                                aria-label="open calculator"
                            >
                                <IconCalculator />
                            </button>
                        </div>
                    </FullWidthCard>

                    {featuredOffer &&
                        Object.keys(featuredOffer)?.length > 0 && (
                            <FullWidthCard
                                className={styles.card}
                                onClick={(event) =>
                                    handleFeaturedOfferClick(event)
                                }
                                id="store-mode-offer"
                                noPadding
                            >
                                <div
                                    className={mergeClasses(
                                        styles.featuredCard
                                    )}
                                >
                                    <img
                                        src={featuredOffer?.digitalviewimage}
                                        alt="featured offer"
                                        aria-hidden="true"
                                    />
                                    <div className={styles.cardInfo}>
                                        <Heading
                                            type="h4"
                                            size={18}
                                            weight="bold"
                                        >
                                            {featuredOffer.name}
                                        </Heading>
                                        <Body
                                            className={styles.ellpsis}
                                            weight="light"
                                        >
                                            {featuredOffer.offerdescription}
                                        </Body>
                                        <AppLink
                                            className={mergeClasses(
                                                styles.actionLink
                                            )}
                                            href="#"
                                            id="store-mode-activate-offer"
                                            onClick={activateAllOffers}
                                        >
                                            {getActivateOfferText()}
                                        </AppLink>
                                    </div>
                                </div>
                            </FullWidthCard>
                        )}

                    {couponsInfo && Object.keys(couponsInfo)?.length > 0 && (
                        <FullWidthCard
                            className={styles.card}
                            onClick={(event) => handleCouponOfferClick(event)}
                            id="store-mode-coupon"
                            noPadding
                        >
                            <div className={mergeClasses(styles.featuredCard)}>
                                <img
                                    src={couponsInfo[0]?.couponInfo?.imageURL}
                                    alt="coupon"
                                    aria-hidden="true"
                                />
                                <div className={styles.cardInfo}>
                                    <Heading type="h4" size={18} weight="bold">
                                        {
                                            couponsInfo[0]?.couponInfo
                                                ?.descriptionText1
                                        }
                                    </Heading>
                                    <Body
                                        className={styles.ellpsis}
                                        weight="light"
                                    >
                                        {
                                            couponsInfo[0]?.couponInfo
                                                ?.descriptionText2
                                        }
                                    </Body>
                                    <AppLink
                                        className={mergeClasses(
                                            styles.actionLink
                                        )}
                                        // href="#"
                                        id="store-mode-see-coupons"
                                        // onClick={seeAllCoupons}
                                    >
                                        See all {couponsInfo.length} store
                                        coupons
                                    </AppLink>
                                </div>
                            </div>
                        </FullWidthCard>
                    )}

                    <div
                        className={mergeClasses(
                            layout.flex,
                            layout.alignCenter,
                            styles.smallCardContainer
                        )}
                    >
                        <FullWidthCard
                            className={mergeClasses(
                                styles.card,
                                styles.smallCard
                            )}
                            noPadding
                            onClick={(event) =>
                                handleRecentPurchaseClick(event)
                            }
                        >
                            <div
                                className={mergeClasses(
                                    layout.flex,
                                    layout.alignCenter,
                                    layout.colGap4
                                )}
                            >
                                <IconRecentPurchase />
                                <Body
                                    size={14}
                                    weight="light"
                                    color={COLORS.darkGray}
                                >
                                    Recent purchases
                                </Body>
                            </div>
                        </FullWidthCard>
                        <FullWidthCard
                            className={mergeClasses(
                                styles.card,
                                styles.smallCard
                            )}
                            noPadding
                            onClick={(event) => handleWeeklyAddClick(event)}
                        >
                            <div
                                className={mergeClasses(
                                    layout.flex,
                                    layout.alignCenter,
                                    layout.colGap4
                                )}
                            >
                                <IconWeeklyAd />
                                <Body
                                    size={14}
                                    weight="light"
                                    color={COLORS.darkGray}
                                >
                                    Weekly Ad
                                </Body>
                            </div>
                        </FullWidthCard>
                    </div>
                    <RecyclingCartridgeEarning />
                </>
            ) : (
                <>
                    <div
                        className={styles.quickLickContainer}
                        // className={mergeClasses(
                        //     layout.flex,
                        //     layout.justifySpaceBetween,
                        //     layout.px5,
                        //     layout.mb4
                        // )}
                    >
                        <QuickLinkCard
                            icon={<IconScanner width={70} height={70} />}
                            text="Scan a product"
                            onClickHandler={(
                                event: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                >
                            ) => handleScanProductClick(event)}
                        />
                        <QuickLinkCard
                            icon={<IconWeeklyAd width={70} height={70} />}
                            text="Weekly Ad"
                            onClickHandler={(
                                event: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                >
                            ) => handleWeeklyAdClick(event)}
                        />
                    </div>
                    <div
                    className={styles.quickLickContainer}
                        // className={mergeClasses(
                        //     layout.flex,
                        //     layout.justifySpaceBetween,
                        //     layout.px5
                        // )}
                    >
                        <QuickLinkCard
                            icon={<IconDollarCoin width={70} height={70} />}
                            text="Hot Deals"
                            onClickHandler={(
                                event: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                >
                            ) => handleHotDealsClick(event)}
                        />
                        <QuickLinkCard
                            icon={<IconPeople width={70} height={70} />}
                            text="Store services"
                            onClickHandler={(
                                event: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                >
                            ) => handleStoreServicesClick(event)}
                        />
                    </div>

                    {/* <FullWidthCard > style={{ margin: '1.9rem 1.3rem 0' }}> */}
                    <FullWidthCard >
                        <div
                            className={mergeClasses(
                                layout.flex,
                                layout.alignCenter
                            )}
                        >
                            <IconProfile width={30} height={30} />
                            <Body
                                size={18}
                                lineHeight={24}
                                padding="0 0.8rem"
                                className={styles.offersText}
                            >
                                Looking for offers?
                                <Button
                                    variant="text"
                                    fontSize={18}
                                    color="textLinkBlue"
                                    className={styles.signInButton}
                                    onClick={(
                                        event: React.MouseEvent<
                                            HTMLButtonElement,
                                            MouseEvent
                                        >
                                    ) => handleSignInClick(event)}
                                >
                                    Sign in
                                </Button>
                            </Body>
                        </div>
                    </FullWidthCard>
                    <Button
                        onClick={handleStoreModeExit}
                        variant="text"
                        fontSize={12}
                        className={styles.exitStoreModeText}
                    >
                        Exit store mode for full app experience
                    </Button>
                    {isBarcodeScannerOpen && (
                        <Brcd
                            isScannerOpen={isBarcodeScannerOpen}
                            onClose={() => setIsBarcodeScannerOpen(false)}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default StoreModeDashboard;
