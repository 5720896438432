import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    ButtonBase,
    Dialog,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import {
    eventMetricsMap,
    useEventsReporter,
    useRouter,
    useUserInfo,
} from 'src/hooks';
import ROUTES from 'src/lib/routes';
import ShippingInfoContext from 'src/lib/contexts/shipping-info-context';
import { Body, Heading } from 'src/components/UI';
import ShippingPrivacyTermsHome from 'src/components/shipping-services/shipping-privacy-terms/shipping-privacy-terms-home';
import clsx from 'clsx';
import { useShipmentTracking } from 'src/lib/contexts/ShipmentTrackingContext';
import useConfigInfo from 'src/hooks/useConfigInfo';
import { Player } from '@lottiefiles/react-lottie-player';
import COLORS from '../../../lib/colors';
import Brcd from '../../BarcodeScanner/Brcd';
import { addShipmentTracking } from '../../../lib/api-client/shipment-services';
import animationImg from '../../../assets/screenshots/animationImg.png';
import LoadingOverlay from '../../loading-overlay';
import ShippingRewardsInfoDialog from '../ShippingRewardsInfoDialog';
import ScanModal from '../components/ScanModal';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        padding: '0 !important',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    shippingServicesContainer: {
        display: 'flex',
        cursor: 'pointer',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        padding: '20px 20px 15px 18px',
        margin: '1.5rem',
    },
    cardCornerPill: {
        position: 'absolute',
        right: '5px',
        bottom: '-5px',
        width: '35%',
    },
    shippingServicesSubContainer: {
        marginTop: '50px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        padding: '6% 11% 6%',
    },
    shippingServicesCTATopText: {
        fontSize: '40px',
        fontFamily: 'StaplesNormsBold',
        lineHeight: '45px',
        marginBottom: '1rem',
        color: '#1E1E1E',
    },
    linkCta: {
        fontWeight: 500,
        marginTop: '15px',
        color: '#086DD2',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '32px',
    },
    shippingServicesCTARightImage: {
        margin: '24px 0 20px',
        width: '150px',
    },
    iconBack: {
        position: 'absolute',
        left: '14px',
        top: '18px',
        zIndex: 1,
    },
    shortText1: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '26px',
        letterSpacing: '0px',
        color: '#1E1E1E',
        marginBottom: '40px',
    },
    saveTimeAlign: {
        fontSize: '18px',
        textAlign: 'center',
        fontFamily: 'StaplesNormsRegular',
        lineHeight: '22px',
        letterSpacing: '-0.46pxpx',
        color: '#1E1E1E',
        margin: '24px 50px',
    },
    imageContainer: {
        position: 'relative',
        // marginRight: '1.25rem',
        display: 'flex',
        alignItems: 'center',
    },
    shippingServiceContainer: {
        display: 'flex',
        flexDirection: 'column',
        background: '#FFFFFF',
        height: '100vh',
        overflowY: 'auto',
    },
    shippingLogo: {
        position: 'fixed',
        width: '100px',
        height: 'auto',
        top: '0',
        right: '0',
    },
    startShipment: {
        height: '40px',
        width: '100%',
        borderRadius: '20px',
        background: '#CC0000',
        marginBottom: '10px',
        '& span': {
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '17px',
            color: '#FFFFFF',
            textAlign: 'center',
        },
        '&:hover': {
            opacity: 0.8,
        },
    },
    savedLabels: {
        height: '40px',
        width: '200px',
        '& span': {
            fontFamily: 'StaplesNormsMedium',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '17px',
            color: '#086DD2',
            textAlign: 'center',
        },
        // '&:hover': {
        //     '& span': {
        //         color: '#FFFFFF',
        //     },
        //     background: '#CC0000',
        // },
    },
    tileButtonContainer: {
        display: 'flex',
    },
    tileButton: {
        fontSize: '15px',
        background: COLORS.brandRed,
        height: '50px',
        // width: '300px',
        borderRadius: '100px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        color: COLORS.primaryWhite,
        fontWeight: 700,
        '& img': {
            width: '54px',
        },
    },
    startShipmentTile: {
        background: `${COLORS.brandRed}`,
        border: `2px solid ${COLORS.brandRed}`,
    },
    paper: { borderRadius: '15px', margin: '24px', padding: '10px 0 0 0' },
    dialogTitle: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        margin: '10px 0 10px 0px',
    },
    dialogWrapper: {
        padding: '10px 20px 30px 30px',
        textAlign: 'center',
        color: COLORS.secondaryNeutralBlack,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogMessage: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: 400,
    },
    closeIcon: {
        position: 'absolute',
        top: '-3px',
        right: '10px',
        backgroundColor: 'rgba(0,0,0,0)',
        minWidth: 'initial',
        padding: '0px',
    },
    closeButton: {
        position: 'relative',
        textAlign: 'center',
    },
    anotherPackage: {
        margin: '0px',
    },
    successIcon: {
        width: '50px',
        marginBottom: '5px',
    },
    privacyLink: {
        bottom: '0px',
        position: 'absolute',
        height: '40px',
        alignSelf: 'center',
        textDecoration: 'underline',
    },
}));

const ShippingServicesIntro = () => {
    const classes = useStyles();
    const { history, query } = useRouter();
    const { analyticsCustomEvent } = useEventsReporter();
    const [showTermsPrivacy, setShowTermsPrivacy] = useState<boolean>(false);
    const privacyPolicyURL = 'https://www.staplesconnect.com/privacypolicy';
    const {
        updateFetchSenderInfo,
        updateReviewStage,
        openScanToTrack,
        updateOpenScanToTrack,
        updateShippingTab,
    } = useContext(ShippingInfoContext);
    const {
        handleClose,
        getShipmentTrackingDetails,
        scanMessage,
        scannerData,
        isLoading,
        setScannerData,
        isScanCompleted,
        trackingError,
    } = useShipmentTracking();
    const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);

    const { isRewardShipperEnable } = useUserInfo();
    const { shortdesc: shippingConfigPoints } = useConfigInfo(
        'ShippingOfferPoints'
    );

    const handleStartShipment = () => {
        localStorage.setItem('startShipment', 'true');
        if (query?.step === 'scanToTrack') {
            history.push({
                pathname: ROUTES.stores,
                search: '?step=scanToTrack',
            });
        } else {
            updateFetchSenderInfo(true);
            updateReviewStage(false);
            history.push({
                pathname: ROUTES.createShippingInformation,
                search: '?step=createShipmentSenderInfo',
            });
            analyticsCustomEvent(eventMetricsMap.shipping, {
                click_text: 'start a shipment',
                element_location: 'main shipping page',
            });
        }
    };

    useEffect(() => {
        if (openScanToTrack) {
            setIsBarcodeScannerOpen(true);
            updateOpenScanToTrack(false);
        }
    }, [openScanToTrack]);
    const handleScan = () => {
        setIsBarcodeScannerOpen(true);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'scan to track',
            element_location: 'main shipping page',
        });
    };
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const handleOpen = () => {
        setOpenInfoDialog(true);
    };
    const handleInfoDialogClose = () => {
        setOpenInfoDialog(false);
    };

    useEffect(() => {
        if (query?.barcode) {
            setScannerData(query.barcode);
        }
    }, [query?.barcode]);

    // const getShipmentTrackingDetails = async (trackingnumber: any) => {
    //     setIsLoading(true);
    //     const response = await addShipmentTracking(trackingnumber);
    //     setScannerData('');
    //     setIsLoading(false);
    //     if (response?.issuccess) {
    //         setTrackingError(true);
    //         if (response?.status === 'SUCCESS') {
    //             setTrackingError(false);
    //             setIsScanCompleted(true);
    //             setScanMessage({
    //                 title: 'Scan successful!',
    //                 description:
    //                     'Visit Labels and tracking to view its current ' +
    //                     'status or scan another package.',
    //             });
    //         } else if (response?.status === 'Fail') {
    //             setScanMessage({
    //                 title: 'Your package couldn’t be found',
    //                 description:
    //                     'We couldn’t find your package at this time. Please try again later.',
    //             });
    //         } else if (response?.status === 'DUPLICATE_TRACKING') {
    //             setScanMessage({
    //                 title: 'Duplicate label',
    //                 description:
    //                     'This label has been previously scanned ' +
    //                     'and is already in the system.',
    //             });
    //         } else if (response?.status === 'INVALID_TRACKING') {
    //             setScanMessage({
    //                 title: `Your package couldn't be found`,
    //                 description:
    //                     'Make sure you have a UPS label and ' +
    //                     'shipment number to start tracking.',
    //             });
    //         } else if (response?.status === 'NONUS_TRACKING') {
    //             setScanMessage({
    //                 title: 'Want to ship internationally?',
    //                 description:
    //                     'Bring your package to your local Staples store ' +
    //                     'and we’ll ship it anywhere in the world.',
    //             });
    //         } else {
    //             setScanMessage({
    //                 title: 'Label couldn’t be scanned',
    //                 description:
    //                     'We couldn’t scan your label at this time. Please try again later.',
    //             });
    //         }
    //         setIsScanCompleted(true);
    //         setScannerData('');
    //     } else {
    //         setTrackingError(true);
    //         setIsScanCompleted(true);
    //         setScanMessage({
    //             title: 'Your package couldn’t be found',
    //             description:
    //                 'We couldn’t find your package at this time. Please try again later.',
    //         });
    //     }
    // };
    useEffect(() => {
        if (scannerData) {
            getShipmentTrackingDetails(scannerData);
        }
    }, [scannerData]);

    const handleSavedLabelShipment = (state: string) => {
        if (state) {
            updateShippingTab(state);
        }
        history.push(ROUTES.shippingServicesLabelShipment);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'labels and tracking',
            element_location: 'main shipping page',
        });
    };
    const handlePrivacyPolicy = () => {
        setShowTermsPrivacy(true);
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'privacy & terms',
            element_location: 'main shipping page',
        });
    };
    const handleShippingGetQuote = () => {
        history.push({
            pathname: ROUTES.getQuoteShippingInformation,
            search: '?step=getEstimator',
        });
        analyticsCustomEvent(eventMetricsMap.shipping, {
            click_text: 'get a quote',
            element_location: 'main shipping page',
        });
    };
    if (isLoading) {
        return (
            <LoadingOverlay
                variant="wordmark"
                theme="transparent-light"
                open={isLoading}
            />
        );
    }

    const InfoTextWrapper = ({
        heading,
        infoText,
    }: {
        heading: string;
        infoText: string;
    }) => (
        <Box>
            <Body size={18} lineHeight={24}>
                {heading}{' '}
            </Body>
            <Body size={16} weight="light" lineHeight={20}>
                {infoText}
            </Body>
        </Box>
    );

    return (
        <>
            <div className={classes.shippingServicesSubContainer}>
                <Heading
                    type="h1"
                    size={32}
                    lineHeight={38}
                    color="textBlack"
                    weight="bold"
                >
                    Earn points when you ship in store
                </Heading>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '50px',
                        marginTop: '5px',
                    }}
                >
                    {isRewardShipperEnable ? (
                        <Box className={classes.column}>
                            <Box
                                sx={{
                                    display: 'ruby',
                                    alignItems: 'center',
                                }}
                            >
                                <Body size={16} weight="light" lineHeight={20}>
                                    Easy Rewards™ members earn{' '}
                                </Body>
                                <Body
                                    size={16}
                                    weight="bold"
                                    color="textPurple"
                                    lineHeight={20}
                                >
                                    {' '}
                                    &nbsp; {shippingConfigPoints} points &nbsp;
                                </Body>
                                <Body size={16} weight="light" lineHeight={20}>
                                    every time they ship or drop off a package
                                    in store.
                                </Body>
                            </Box>

                            <InfoTextWrapper
                                heading="Package drop-off"
                                infoText="Tap scan to track to capture the label of your package(s) and drop off with a Staples® associate."
                            />
                            <InfoTextWrapper
                                heading="Full service shipping"
                                infoText="Start a shipment now or at your local Staples store and provide your Easy Rewards number at checkout."
                            />
                        </Box>
                    ) : null}

                    <ButtonBase
                        className={clsx(
                            classes.tileButton,
                            classes.startShipmentTile
                        )}
                        onClick={handleStartShipment}
                    >
                        <div>Continue</div>
                    </ButtonBase>
                </Box>

                <ButtonBase
                    className={classes.savedLabels}
                    onClick={handleOpen}
                >
                    <span style={{ margin: '5px' }}>Learn more</span>
                </ButtonBase>

                {isBarcodeScannerOpen && (
                    <Brcd
                        isScannerOpen={isBarcodeScannerOpen}
                        shippingScan
                        onClose={() => {
                            setIsBarcodeScannerOpen(false);
                            handleClose();
                        }}
                    />
                )}

                {isScanCompleted && (
                    <ScanModal
                        handleSavedLabelShipment={handleSavedLabelShipment}
                        handleScan={handleScan}
                    />
                )}
            </div>
            {/* <Player
                autoplay
                loop
                style={{ marginRight: '-4px' }}
                className={classes.cardCornerPill}
                src={require('../../../assets/lotties/rewards-enrollment/sm_digital_card_corner_pills.json')}
                aria-label="Rewards animation"
                // tabIndex={0} // Make focusable if necessary
            /> */}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    lineHeight: 0,
                }}
            >
                <img src={animationImg} alt="img" />
            </Box>
            <ShippingRewardsInfoDialog
                points={shippingConfigPoints}
                hideBtn
                onClose={handleInfoDialogClose}
                onOpen={handleOpen}
                open={openInfoDialog}
            />
        </>
    );
};

export default ShippingServicesIntro;
